<div class="header" style="background-color: #0B6D70;">
    <div class="left-sec">
        <ng-container *ngIf="welcomeUserMsg">
            <h1 style="color: white;">Dashboard</h1>
            
        </ng-container>
        <h1 *ngIf="manageCaregiversHeading">Manage Guest</h1>
        <h1 *ngIf="manageHostHeading">Manage Host</h1>
        <h1 *ngIf="manageProblemHeading">Manage Problem</h1>
        <h1 *ngIf="manageClientHeading">Contact Us</h1>
        <h1 *ngIf="manageServicesHeading">Alerts</h1>
        <h1 *ngIf="manageTutorialHeading">Extra Taxes</h1>
        <h1 *ngIf="manageConvenienceHeading">Manage Convenience Fee</h1>
        <h1 *ngIf="manageDiseasesHeading">Manage Diseases</h1>
        <h1 *ngIf="dashboardHeading">Dashboard</h1>
        <h1 *ngIf="changePasswordHeading" style="color: white;">Change Password</h1>
        <h1 *ngIf="manageInterestdHeading">Alerts</h1>
        <h1 *ngIf="manageLocalHeading">Manage Local</h1>
        <h1 *ngIf="broadcastNotificationHeading">Broadcast Notifications</h1>
        <h1 *ngIf="broadcastNotificationHeading">Broadcast Notifications</h1>



        
    </div>


</div>


<ng-template #template>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center delete-modal align-items-center">
        <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
        <p>Are you sure you <br>want to logout?</p>
        <div class="modal-btn d-flex justify-content-around">
            <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
            <button class="btn btn-blue" (click)="confirm()">Yes</button>
        </div>
    </div>

</ng-template>