

export const environment = {
  production: false,
  

  
   API_URL: "https://api.plugp2p.com/api/", 
   










  


  
};
