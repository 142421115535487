import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-template',
  templateUrl: './manage-template.component.html',
  styleUrls: ['./manage-template.component.scss']
})
export class ManageTemplateComponent implements OnInit {

  constructor(
    private http: HttpClient

  ) { }

  ngOnInit(): void {
    //intentionally left space for future use
    
  }

  

}
