<app-layout></app-layout>
<div class="content">
    <div style="display: flex; justify-content: space-between; margin-bottom: 15px;">
        <div style="width: 45%; background-color: #0B6D70; padding: 20px; border-radius: 8px; color: #fff; height: 150px; margin-top: 40px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <h3 style="font-weight: bold; margin-bottom: 5px;">Total Number of Registered Guests</h3>
            <p style="font-weight: bold;">{{registeredGuest}}</p>
        </div>
        <div style="width: 45%; background-color: #0B6D70; padding: 20px; border-radius: 8px; color: #fff; height: 150px; margin-top: 40px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <h3 style="font-weight: bold; margin-bottom: 5px;">Total Number of Registered Hosts</h3>
            <p style="font-weight: bold;">{{registeredHost}}</p>
        </div>
    </div>
   
 
    
    <div style="display: flex; justify-content: space-between;">
        <div style="width: 45%; background-color: #0B6D70; padding: 20px; border-radius: 8px; color: #fff; height: 150px; margin-top: 40px; display: flex; flex-direction: column; justify-content: center;align-items: center;">
            <h3 style="font-weight: bold;margin-bottom: 5px;">Total Number of Bookings</h3>
            <p style="font-weight: bold;">{{registeredBookings}}</p>
        </div>
        <div style="width: 45%; background-color: #0B6D70; padding: 20px; border-radius: 8px; color: #fff; height: 150px; margin-top: 40px; display: flex; flex-direction: column;justify-content: center; align-items: center;">
            <h3 style="font-weight: bold;margin-bottom: 5px;">Total Revenue Generated</h3>
            <p style="font-weight: bold;">{{ formatRevenue(registeredGenerated)  }}</p>
        </div>
    </div>
    
    
    
</div>
