import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  { path: "", redirectTo: "auth", pathMatch: "full" },
  {
    path: "edit-profile",
    component: EditProfileComponent,
  }, 
  {
    path: "",
    component: ViewsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "change-password", component: EditProfileComponent },

      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },

      {
        path: "analytics-dashboard",
        loadChildren: () =>
          import("../app/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          )
      },

      {
        path: "manage-guest",
        loadChildren: () =>
          import("../app/views/manage-guest/manage-guest.module").then(
            (m) => m.ManageGuestModule
          )
      },

      {
        path: "manage-host",
        loadChildren: () =>
          import("../app/views/manage-host/manage-host.module").then(
            (m) => m.ManageCaregiversModule
          )
      },

      {
        path: "email-template",
        loadChildren: () =>
          import("../app/views/manage-email/manage-email.module").then(
            (m) => m.ManageEmailModule
          )
      },

      {
        path: "manage-report",
        loadChildren: () =>
          import("../app/views/manage-reported/manage-reported.module").then(
            (m) => m.ManageReportedModule
          )
      },

      {
        path: "manage-template",
        loadChildren: () =>
          import("../app/views/manage-template/manage-template.module").then(
            (m) => m.ManageTemplateModule
          )
      },

      {
        path: "manage-problem",
        loadChildren: () =>
          import("../app/views/manage-problem/manage-problem.module").then(
            (m) => m.ManageProblemModule
          )
      },
     

    ],
  },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
