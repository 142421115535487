
<div class="sidebar" style="background-color: #0B6D70;border: none;">

  
  <div class="logo mt-2"><img src="../../../assets/icons/LOGO (white) (0)@0 1.png" alt="logo" style=" object-fit: contain;height: 74px;margin-top: 20px;margin-left: 71px;margin-bottom: 35px;"></div>

  <div class="header-links">

 

    <a routerLink="/analytics-dashboard" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
      <span class="sidebar-icon">
          <img class="white-icon" src="../../../assets/icons/dashboard.svg" alt="" height="24" width="24">
      </span>
      <span class="link-text" style="color: white;">Reporting</span>
  </a>

  <a routerLink="/manage-guest" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
    <span class="sidebar-icon">
        <img class="white-icon" src="../../../assets/images/manage sub-admin.png" alt="" height="24" width="24">
    </span> 
    <span class="link-text" style="color: white;">Manage Guest</span>
</a>

<a routerLink="/manage-host" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/images/property.png" alt="" height="24" width="24">
  </span>
  <span class="link-text" style="color: white;">Manage Host</span>
</a>

<a routerLink="/manage-problem" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/images/reporting.png" alt="" height="24" width="24">
  </span>
  <span class="link-text" style="color: white;">Manage Problem</span>
</a>



<a routerLink="/manage-report" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/images/vendor.png" alt="" height="24" width="24">
  </span>
  <span class="link-text" style="color: white;">View Reported Problem</span>
</a>


  
  <a routerLink="/edit-profile" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
    <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/images/change paswordd.png" alt="" height="24" width="24">
    </span>
    <span class="link-text" style="color: white;">Change Password</span>
  </a>
  


  
  
  <a (click)="openModal(template)" routerLinkActive="active-link"  id="logout" style="color: rgba(96, 111, 140, 1);">
      <span class="sidebar-icon">
          <img class="white-icon" src="../../../assets/icons/Logout.png" alt="" height="24" width="24">
      </span>
      <span class="link-text" style="color: white;">Logout</span>
  </a>
   
  
<ng-template #template>


  <div class="modal-body text-center delete-modal align-items-center" style="background-color: #0B6D70;">
    <button type="button" class="close close-button-black-border" aria-label="Close" (click)="modalRef.hide()"
    routerLink="/careers/list">
    <span aria-hidden="true" class="logIcon">&times;</span>
</button>
      <img  src="../../../../../assets/images/create-successfull-img.svg" alt="">
      <p style="color: white;">Are you sure you <br>want to logout</p>
      <div class="modal-btn d-flex justify-content-around">
          <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
          <button class="btn btn-blue" (click)="confirm()" style="background-color: #74d0d3;">Yes</button>
      </div>
  </div>
</ng-template>

  </div>
</div>



