<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Welcome to Ride Loud</title>
<style>
  * {
    box-sizing: border-box; 
  }
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #ECECEC;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Arial', sans-serif;
  }
  .card {
    width: 100%;
    max-width: 800px; 
    margin: 20px;
    background: #C3C99F; 
  }
  .card-header, .card-footer {
    text-align: center;
    padding: 20px;
  }
  .card-header img {
    max-width: 200px; 
    height: auto;
  }
  .card-content {
    background: #ffffff; 
    padding: 40px 20px;
    margin: 0 20px; 
    border-radius: 8px; 
  }
  .card-content h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
  }
  .card-content p, .card-content ul {
    color: #666;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .card-content ul {
    list-style: none; 
    padding: 0;
    margin: 0;
  }
  .card-content li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
  }
  .card-content li:before {
    content: '•'; 
    color: #6DAB3C; 
    position: absolute;
    left: 0;
  }
  .card-footer a {
    color: #333;
    text-decoration: none;
    margin: 0 10px;
  }
  @media (max-width: 768px) {
    .card-content {
      padding: 20px;
    }
  }
</style>
</head>
<body>
  <div class="card">
    <div class="card-header">
      <img src="../../../assets/images/image 31.png" alt="Ride Loud Logo">
    </div>
    <div class="card-content">
      <h2 style="text-align: center;">Welcome to Ride Loud</h2>
      <p>Welcome to Ride Loud, your go-to platform for connecting with fellow outdoor enthusiasts, discovering thrilling events, and sharing your passion for adventure.</p>
      <p>At Ride Loud, you'll experience:</p>
      <ul>
        <li>Community: Connect with people from around the world, share your experiences, and join discussions on everything related to outdoor recreation and activities.</li>
        <li>Events: Explore a diverse range of events from adrenaline-pumping races to leisurely group activities. Whether you're a seasoned athlete or just starting out, there's something for you.</li>
        <li>Social Features: Stay connected with your friends, share photos and videos of your adventures, and keep track of upcoming events with our user-friendly interface.</li>
      </ul>
      <p>We're thrilled to have you on board and can't wait for you to dive into the exciting world of adventure with Ride Loud.</p>
    </div>
    <div class="card-footer">
      <a href="#">Terms & Conditions</a> | <a href="#">Privacy Policy</a> | <a href="#">Contact Us</a>
    </div>
  </div>
</body>
</html>
