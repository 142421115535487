import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { DashboardService } from '../services/dashboard.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public canvas: any;
  public ctx: any;
  public chart: any;
  dashboardData: any = [];
  dashboardAllData: any;
  graphData: any;
  months: number = 6;
  currentUser: any;
  registeredUsers: any;
  registeredGuest:any;
  registeredHost:any;
  registeredBookings:any;
  registeredGenerated:any;
  registeredLocal: any;
  totalBookings: any;
  totalRevenue:any;
  formattedNumber: any;


  constructor(
    private _header: HeaderService,
    private dashboardService: DashboardService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this._header.welcomeUserMsg.next(true);
    this.currentUser = this.authService.currentUserValue;
    this.mainEmailId =  this.currentUser?.email
    this.getCount()
  }
  mainEmailId: any;

  ngOnDestroy() {
    this._header.welcomeUserMsg.next(false);
  }

  getCount() {
    this.dashboardService.getDashboardCount().subscribe((res: any) => {
      if (res.status == 200) {
        this.registeredGuest = res.output.guestCount;
        this.registeredHost = res.output.hostCount;
        this.registeredBookings = res.output.totalBookings;
        this.registeredGenerated = res.output.totalRevenue;
      }
    })
  }

  formatRevenue(amount: number): string {
    return amount.toFixed(2);
}


  getFormattedNumber(value: any) {
 this.formattedNumber = value.toLocaleString('en-US');
        document.getElementById("client12").innerHTML = this.formattedNumber;
        const numberFormat = this.formattedNumber
        console.log(this.formattedNumber);
        return numberFormat
  }

 getCounter() {
 
  const formattedNumber = this.totalRevenue.toLocaleString('en-US');
  document.getElementById("client1").innerHTML = formattedNumber;
  console.log(formattedNumber);
}



 

}
