import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HeaderService } from 'src/app/shared/services/header.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from '../auth/services/auth.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit, OnDestroy {
  hide = true;
  hide1 = true;
  hide2 = true;



  userType: any;
  message: string;
  imagePath: any;
  imgURL: any;
  uploaded: boolean = false;
  fileName: any;
  currentUser: any;
  passChangeMsg: boolean = false;
  modalRef: BsModalRef;

  submitted: boolean = false;
  changed: boolean = false;


  imgChangeEvt: any = '';
  cropImgPreview: any = '';
  singalurl: any;
  selectedFile: any;
  @ViewChild('template2') template2: TemplateRef<any>;
  @ViewChild('myFile2') myFile2: ElementRef;



  passwordForm = new FormGroup({
    oldPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(6),Validators.pattern('^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$')]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6),Validators.pattern('^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$')]),
  })

  get p() {
    return this.passwordForm.controls;
  }

  constructor(
    private _header: HeaderService,
    private authService: AuthService,
    private sharedService: SharedService,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) { }

  lang: string = 'eng';
  ngOnInit(): void {
    let currentUser = localStorage.getItem('currentUser');
    this.userType = JSON.parse(currentUser).userType;
    this.lang = localStorage.getItem('lang');
    this._header.changePasswordHeading.next(true);
    this.currentUser = this.authService.currentUserValue;
    
  }
  ngOnDestroy() {
    this._header.changePasswordHeading.next(false);
  }

  strongPasswordError: boolean = false;
  samePasswordError: boolean = false;
  allFieldsEntered = false;
  password: boolean = false;



  checkFieldsEntered() {
    const { oldPassword, newPassword, confirmPassword } = this.passwordForm.value;
    this.allFieldsEntered = oldPassword && newPassword && confirmPassword;
    this.match = newPassword === confirmPassword;
    this.samePasswordError = oldPassword === newPassword;

    const newPasswordControl = this.passwordForm.get('newPassword');
    if (newPasswordControl.errors) {
      this.strongPasswordError = newPasswordControl.errors.pattern;
    } else {
      this.strongPasswordError = false;
    }
  } 

  isNewPasswordSameAsOldPassword() {
    const oldPassword = this.passwordForm.get('oldPassword').value;
    const newPassword = this.passwordForm.get('newPassword').value;
  
    this.password = oldPassword === newPassword;
  }

  passwordsMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const oldPassword = control.get('oldPassword').value;
      const newPassword = control.get('newPassword').value;

      if (oldPassword !== newPassword) {
        return null;
      }

      return { samePassword: true };
    };
  }

 
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  match: boolean = true;
  showpassMatch: any
  showOldRequired: any
changePassword() {
  this.changed = true;
  this.showOldRequired = false
 
  if (this.passwordForm.invalid || this.passwordForm.pristine) return;

  const oldPassword = this.passwordForm.value.oldPassword;
    const newPassword = this.passwordForm.value.newPassword;

    if (oldPassword === newPassword) {
          this.toastr.error("New password cannot be the same as the old password");
          return;
        }
  this.changed = true;
  let data = {
    oldPassword: this.passwordForm.value.oldPassword,
    newPassword: this.passwordForm.value.newPassword,
  };
  if (this.passwordForm.value.newPassword !== this.passwordForm.value.confirmPassword) {
    this.showpassMatch = true
  } else  if (this.passwordForm.value.newPassword === this.passwordForm.value.confirmPassword) {
    this.match = true;
    this.authService.changePassword(data).subscribe(
      (res: any) => {
        console.log('changepassword res', res);
        if (res.message) {
                this.passwordForm.reset();
                this.toastr.success("Password Changed Successfully");
                localStorage.removeItem('currentUser');
                this.router.navigateByUrl('/login');
              }
                } ,  (err) => {
                  
                    this.toastr.error("Invalid Old Password");
                  });

            }
          }




  
  
  
  
}
