

import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../auth/services/auth.service';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import { tap } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private router: Router,
        private toastrService: ToastrService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this.authService.currentUserValue;
        const basicAuthDetails = this.authService.basicAuthentication;

        if (currentUser) {
            request = request.clone({
                setHeaders: {
                    'accessToken':  currentUser.accessToken,
                    Authorization: 'Basic ' + btoa(basicAuthDetails.userName + ':' + basicAuthDetails.password)
                }
            });
        }

        return next.handle(request).pipe(
            tap(
                event => {
                    if (event instanceof HttpResponse) {
                        // Handle successful response
                    }
                },
                error => {
                    if (error instanceof HttpErrorResponse) {
                        if (error.status === 400 && error.error && error.error.errorCode === 1500) {
                            // Handle the specific error code indicating unauthorized action due to multiple logins
                            this.handleMultipleLoginError();
                        } else {
                            // Handle other errors
                            this.handleOtherErrors(error);
                        }
                    }
                }
            )
        );
    }

    private handleMultipleLoginError(): void {
        // Handle the error indicating unauthorized action due to multiple logins
        this.router.navigate(['/auth/login']);
        this.toastrService.error('You are logged in on another device. Please log in again.');
    }

    private handleOtherErrors(error: HttpErrorResponse): void {
        // Handle other HTTP errors
        if (error.status === 400) {
            // Handle unauthorized error
            this.router.navigate(['/auth/login']);
           
        } else if (error.status === 503) {
            // Handle service unavailable error
            this.router.navigate(['/auth/login']);
            this.toastrService.error('Service unavailable. Please try again later.');
        } else if (error.status === 0) {
            // Handle no internet connection error
            this.router.navigate(['/auth/login']);
            this.toastrService.error('No Internet Connection');
        } 
    }
}




