<head>
  <title>P2P Log-In Page</title>
</head>
<body class="body" style="background-color: #0B6D70;">

  <header style="background-color: #0B6D70;">
      <h1 id="CS"><img src="../../../../assets/icons/LOGO (white) (0)@0 1.png" alt="" style="margin-left: 56px;"></h1>
  </header>    

  <div class="row" >
      <div class="col container item-1"> 
          <p id="hcontent">Peer-to-Peer <br> EV Charging Everywhere.</p>
      </div>

      <div class="col container item-2">
        <form class="mainCS"  (submit)="login()" [formGroup]="loginForm" style="margin-top: -55px;">
          <div style="text-align: center;"></div>
      
          <div id="username" class="form-field" style="margin-top: 15px;">
            
            <p style="font-weight: bold; font-size: 15px;">Email Address</p>
            <input type="text" formControlName="email" required placeholder="Enter Email" autofocus  style="width: 100%; height: 40px; border-radius: 10px;">

            <div *ngIf="submitted && username.hasError('required')">Username is <strong>required</strong></div>
          </div>
      
          <div id="password" class="form-field" style="position: relative;margin-top: 15px;">
            <p style="font-weight: bold; font-size: 15px;">Password</p>
            <input [type]="hide ? 'password' : 'text'" formControlName="password" placeholder="Enter Password" style="width: 100%; height: 30px;  height: 40px; border-radius: 10px;">
            <mat-icon class="placeholder" (click)="myFunction()" style="position: absolute; top: 52px;
            right: 13px ">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <div *ngIf="submitted && password.hasError('required')">Password is <strong>required</strong></div>
          </div>
          
      
          <button class="btn btn-outline-dark" type="submit" id="login" style="background-color: #0B6D70; margin-top: 30px; width: 100%; height: 40px; border-radius: 10px; display: flex; align-items: center; justify-content: center;">
            <b>Log In</b>
        </button>
        
      
          <br><br>
      
          <a routerLink="/auth/forgot-password" id="forgotPassword" style="font-weight: bold; display: block; text-align: right; margin-top: -20px;">Forgot Password?</a>
          <br><br>
      
        </form>
      </div>
      
  </div>
</body>
